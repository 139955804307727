import {useEffect, useMemo} from 'react';
import {atom, useRecoilState} from 'recoil';
import {ROLE_ALL_QUERY, USERS_ALL_WHERE_QUERY} from '../../data/QueriesGL';
import useQueryFHG from '../../fhg/hooks/data/useQueryFHG';

export const userInfoState = atom({
   key: 'userInfoStateKey',
   default: {username: '', email: '', name: '', roleId: '', roleName: '', id: '', groups: [], signOut: undefined},
});

export default function UserInfo({authState, children}) {
   const [userInfo, setUserInfo] = useRecoilState(userInfoState);
   const [rolesData] = useQueryFHG(ROLE_ALL_QUERY);
   const [userData] = useQueryFHG(
      USERS_ALL_WHERE_QUERY,
      {variables: {username: userInfo?.username}},
      'user.type',
      null,
      null
   );

   const userRoleName = useMemo(() => {
      return rolesData?.role?.find((role) => role.id === userInfo?.roleId)?.name;
   }, [userInfo, rolesData]);


   useEffect(() => {
      if (authState) {
         const groups = authState?.user?.signInUserSession?.idToken?.payload?.['cognito:groups'] ?? [];
         const email = authState?.user?.attributes?.email;
         setUserInfo((userInfo) => ({
            ...userInfo,
            roleName: userRoleName,
            username: authState?.user?.username,
            groups,
            email,
         }));
      }
   }, [authState, userRoleName, setUserInfo]);

   useEffect(() => {
      if (userData?.users?.length) {
         setUserInfo((userInfo) => ({
            ...userInfo,
            name: userData?.users[0].name,
            roleId: userData?.users[0].roleId,
            id: userData?.users[0].id,
         }));
      }
   }, [userData?.users, setUserInfo]);

   return children;
}
