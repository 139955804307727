import gql from 'graphql-tag';
import {PAPERWORK_TYPE} from '../Constants';
import {PHONE_TYPE} from '../Constants';
import {SOCIAL_TYPE} from '../Constants';
import {CONTACT_TYPE} from '../Constants';
import {UNDELETE_ACTION} from '../fhg/hooks/data/useMutationFHG';
import {DELETE_ACTION} from '../fhg/hooks/data/useMutationFHG';
import {CREATE_UPDATE_ACTION} from '../fhg/hooks/data/useMutationFHG';
import {BILLING_ENTRY_FRAGMENT, ROLE_FRAGMENT} from './FragmentsGL';
import {USER_FRAGMENT} from './FragmentsGL';
import {PERSON_FRAGMENT} from './FragmentsGL';
import {TYPE_FRAGMENT} from './FragmentsGL';
import {CONTACT_SOCIAL_FRAGMENT} from './FragmentsGL';
import {PHONE_FRAGMENT} from './FragmentsGL';
import {STATE_FRAGMENT} from './FragmentsGL';
import {CITY_FRAGMENT} from './FragmentsGL';
import {CLIENT_CONTACT_FRAGMENT} from './FragmentsGL';

export const CLIENT_CONTACT_CREATE_UPDATE = {
   mutation: gql`
      mutation clientContactCreateUpdate(
         $id: UUID!
         $clientId: UUID
         $contactTypeId: UUID
         $name: String
         $email: String
         $isPrimary: Boolean
      ) {
         clientContact: clientContact_CreateUpdate(
            clientContact: {
               id: $id
               clientId: $clientId
               contactTypeId: $contactTypeId
               name: $name
               email: $email
               isPrimary: $isPrimary
            }
         ) {
            ...clientContactInfo
         }
      }
      ${CLIENT_CONTACT_FRAGMENT}
   `,
   typeKey: 'contact.type',
   actionKey: CREATE_UPDATE_ACTION,
};

export const CLIENT_CONTACT_DELETE = {
   mutation: gql`
      mutation clientContactDelete($id: UUID!) {
         clientContact_Delete(clientContactId: $id)
      }
   `,
   typeKey: 'clientContact.type',
   actionKey: DELETE_ACTION,
};

export const CITY_ALL_QUERY = gql`
   query getCityAll {
      cities: city_All {
         ...CityInfo
      }
   }
   ${CITY_FRAGMENT}
`;

export const CITY_BY_ID_QUERY = gql`
   query getCityById($cityId: UUID!) {
      city: city_ById(cityId: $cityId) {
         ...CityInfo
      }
   }
   ${CITY_FRAGMENT}
`;

export const CITY_CREATE_UPDATE = {
   mutation: gql`
      mutation cityCreateUpdate($id: UUID!, $name: String) {
         city: city_CreateUpdate(city: {id: $id, name: $name}) {
            ...CityInfo
         }
      }
      ${CITY_FRAGMENT}
   `,
   typeKey: 'city.type',
   actionKey: CREATE_UPDATE_ACTION,
};

export const getCityCacheQueries = () => {
   return [{query: CITY_ALL_QUERY, queryPath: 'cities'}];
};

export const STATE_ALL_QUERY = gql`
   query getStateAll {
      states: state_All {
         ...StateInfo
      }
   }
   ${STATE_FRAGMENT}
`;

export const STATE_BY_ID_QUERY = gql`
   query getStateById($stateId: UUID!) {
      state: state_ById(stateId: $stateId) {
         ...StateInfo
      }
   }
   ${STATE_FRAGMENT}
`;

export const CLIENT_CONTACT_ALL_QUERY = gql`
   query getAllClientContact($clientId: [UUID]) {
      contacts: clientContact_AllWhere(clientContactSearch: {clientId: $clientId}) {
         ...clientContactInfo
      }
   }
   ${CLIENT_CONTACT_FRAGMENT}
`;

export const CONTACT_CLIENT_BY_ID_QUERY = gql`
   query getClientContactById($contactId: UUID!) {
      contact: clientContact_ById(clientContactId: $contactId) {
         ...clientContactInfo
      }
   }
   ${CLIENT_CONTACT_FRAGMENT}
`;

export const getClientContactCacheQueries = (clientId) => {
   return [{query: CLIENT_CONTACT_ALL_QUERY, variables: {clientId}, queryPath: 'contacts'}];
};
export const PHONE_ALL_WHERE_QUERY = gql`
   query getContactPhoneAllWhere($clientContactId: [UUID]) {
      phone: contactPhone_AllWhere(contactPhoneSearch: {clientContactId: $clientContactId}) {
         ...phoneInfo
      }
   }
   ${PHONE_FRAGMENT}
`;

// export const PHONE_BY_ID_QUERY = gql`
//    query getContactPhoneById($contactPhoneId: UUID!) {
//       phone: contactPhone_ById(contactPhoneId: $contactPhoneId) {
//          ...phoneInfo
//       }
//    }
//    ${PHONE_FRAGMENT}
// `;

export const CONTACT_PHONE_CREATE_UPDATE = {
   mutation: gql`
      mutation contactPhoneCreateUpdate(
         $id: UUID!
         $clientContactId: UUID
         $phone: String
         $phoneType: String
         $phoneTypeId: UUID
      ) {
         contactPhone: contactPhone_CreateUpdate(
            contactPhone: {
               id: $id
               clientContactId: $clientContactId
               phone: $phone
               phoneType: $phoneType
               phoneTypeId: $phoneTypeId
            }
         ) {
            ...phoneInfo
         }
      }
      ${PHONE_FRAGMENT}
   `,
   typeKey: 'contact.type',
   actionKey: CREATE_UPDATE_ACTION,
};

export const CONTACT_PHONE_DELETE = {
   mutation: gql`
      mutation contactPhoneDelete($id: UUID!) {
         contactPhone_Delete(contactPhoneId: $id)
      }
   `,
   typeKey: 'phone.type',
   actionKey: CREATE_UPDATE_ACTION,
};

export const getPhoneCacheQueries = (clientContactId) => {
   return [{query: PHONE_ALL_WHERE_QUERY, variables: {clientContactId}, queryPath: 'phone'}];
};

export const SOCIAL_ALL_WHERE_QUERY = gql`
   query getContactSocialAllWhere($clientContactId: [UUID]) {
      social: contactSocial_AllWhere(contactSocialSearch: {clientContactId: $clientContactId}) {
         ...contactSocialInfo
      }
   }
   ${CONTACT_SOCIAL_FRAGMENT}
`;

export const CONTACT_SOCIAL_CREATE_UPDATE = {
   mutation: gql`
      mutation contactSocialCreateUpdate(
         $id: UUID!
         $clientContactId: UUID
         $name: String
         $socialTypeId: UUID
         $url: String
      ) {
         contactSocial: contactSocial_CreateUpdate(
            contactSocial: {
               id: $id
               clientContactId: $clientContactId
               name: $name
               socialTypeId: $socialTypeId
               url: $url
            }
         ) {
            ...contactSocialInfo
         }
      }
      ${CONTACT_SOCIAL_FRAGMENT}
   `,
   typeKey: 'social.type',
   actionKey: CREATE_UPDATE_ACTION,
};

export const CONTACT_SOCIAL_DELETE = {
   mutation: gql`
      mutation contactSocialDelete($id: UUID!) {
         contactSocial_Delete(contactSocialId: $id)
      }
   `,
   typeKey: 'social.type',
   actionKey: DELETE_ACTION,
};

export const getSocialCacheQueries = (clientContactId) => {
   return [{query: SOCIAL_ALL_WHERE_QUERY, variables: {clientContactId}, queryPath: 'social'}];
};

export const TYPE_ALL_QUERY = gql`
   query getTypeAll {
      types: type_All {
         id
         category
         name
         label: name
      }
   }
`;

export const TYPE_ALL_WHERE_QUERY = gql`
   query getTypeWhereAll($id: [UUID], $category: [String]) {
      types: type_AllWhere(typeSearch: {id: $id, category: $category}) {
         id
         category
         name
         label: name
      }
   }
`;

export const TYPE_CREATE_UPDATE = {
   mutation: gql`
      mutation typeCreateUpdate($id: UUID!, $category: String, $name: String) {
         type: type_CreateUpdate(type: {id: $id, category: $category, name: $name}) {
            ...typeInfo
         }
      }
      ${TYPE_FRAGMENT}
   `,
   typeKey: 'type.type',
   actionKey: CREATE_UPDATE_ACTION,
};

export const TYPE_DELETE = {
   mutation: gql`
      mutation typeDelete($typeId: UUID!) {
         type_Delete(typeId: $typeId)
      }
   `,
   typeKey: 'type.type',
   actionKey: DELETE_ACTION,
};

export const getTypeCacheQueries = () => {
   return [
      {
         query: TYPE_ALL_QUERY,
         queryPath: 'types',
      },
      {
         query: TYPE_ALL_WHERE_QUERY,
         variables: {category: [CONTACT_TYPE, SOCIAL_TYPE, PHONE_TYPE]},
         queryPath: 'types',
      },
      {
         query: TYPE_ALL_WHERE_QUERY,
         variables: {category: [PAPERWORK_TYPE]},
         queryPath: 'types',
      },
      {
         query: TYPE_ALL_WHERE_QUERY,
         variables: {category: [SOCIAL_TYPE]},
         queryPath: 'types',
      },
   ];
};

export const W_9_STATUS_ALL_QUERY = gql`
   query getW9StatusAll {
      w9Status: w9Status_All {
         id
         name
      }
   }
`;

// export const LOCATION_ALL_QUERY = gql`
//    query getLocationAll {
//       locations: location_All {
//          ...locationInfo
//       }
//    }
//    ${LOCATION_FRAGMENT}
// `;
//
// export const LOCATION_NAME_ALL_QUERY = gql`
//    query getLocationNameAll {
//       locations: location_All {
//          ...locationNameInfo
//       }
//    }
//    ${LOCATION_NAME_FRAGMENT}
// `;

// export const getLocationCacheQueries = () => {
//    return [{query: LOCATION_NAME_ALL_QUERY, queryPath: 'locations'}];
// };

// export const LOCATION_CREATE_UPDATE = {
//    mutation: gql`
//       mutation locationCreateUpdate(
//          $id: UUID!
//          $addressLineOne: String
//          $addressLineTwo: String
//          $cityId: UUID
//          $stateId: UUID
//          $zipCode: String
//          $direction: String
//          $name: String
//          $physDeliveryMethodId: UUID
//          $lat: Float
//          $lng: Float
//       ) {
//          location: location_CreateUpdate(
//             location: {
//                id: $id
//                addressLineOne: $addressLineOne
//                addressLineTwo: $addressLineTwo
//                cityId: $cityId
//                stateId: $stateId
//                direction: $direction
//                name: $name
//                zipCode: $zipCode
//                physDeliveryMethodId: $physDeliveryMethodId
//                lat: $lat
//                lng: $lng
//             }
//          ) {
//             ...locationInfo
//          }
//       }
//       ${LOCATION_FRAGMENT}
//    `,
//    typeKey: 'location.type',
//    actionKey: CREATE_UPDATE_ACTION,
// };

// export const LOCATION_DELETE = {
//    mutation: gql`
//       mutation locationDelete($id: UUID!) {
//          location_Delete(locationId: $id)
//       }
//    `,
//    typeKey: 'location.type',
//    actionKey: DELETE_ACTION,
// };

// export const LOCATION_UNDELETE = {
//    mutation: gql`
//       mutation locationUndelete($id: UUID!) {
//          location_UnDelete(locationId: $id)
//       }
//    `,
//    typeKey: 'location.type',
//    actionKey: UNDELETE_ACTION,
// };

// USERS
export const USER_ALL_QUERY = gql`
   query getAllUser($includeDeleted: Boolean) {
      users: user_All(includeDeleted: $includeDeleted) {
         ...userInfo
      }
   }
   ${USER_FRAGMENT}
`;

export const USERS_ALL_WHERE_QUERY = gql`
   query getUsersAllWhere(
      $id: [UUID]
      $contactName: [String]
      $username: [String]
      $isDeleted: [Boolean]
      $roleId: [UUID]
      $supervisorId: [UUID]
   ) {
      users: user_AllWhere(
         userSearch: {
            id: $id
            contactName: $contactName
            username: $username
            roleId: $roleId
            isDeleted: $isDeleted
            supervisorId: $supervisorId
         }
      ) {
         ...userInfo
      }
   }
   ${USER_FRAGMENT}
`;

export const USER_BY_ID_QUERY = gql`
   query getUserById($id: UUID!) {
      user: user_ById(userId: $id) {
         ...userInfo
      }
   }
   ${USER_FRAGMENT}
`;

export const USER_CREATE_UPDATE = {
   mutation: gql`
      mutation userCreateUpdate(
         $id: UUID!
         $email: String
         $username: String
         $contactName: String
         $password: String
         $roleId: UUID
         $supervisorId: UUID
      ) {
         user: user_CreateUpdate(
            user: {
               id: $id
               email: $email
               username: $username
               contactName: $contactName
               password: $password
               roleId: $roleId
               supervisorId: $supervisorId
            }
         ) {
            ...userInfo
         }
      }
      ${USER_FRAGMENT}
   `,
   typeKey: 'user.type',
   actionKey: CREATE_UPDATE_ACTION,
};

export const USER_DELETE = {
   mutation: gql`
      mutation userDelete($id: UUID!) {
         user_Delete(userId: $id)
      }
   `,
   typeKey: 'user.type',
   actionKey: DELETE_ACTION,
};

export const USER_UNDELETE = {
   mutation: gql`
      mutation userUndelete($id: UUID!) {
         user_UnDelete(userId: $id)
      }
   `,
   typeKey: 'user.type',
   actionKey: UNDELETE_ACTION,
};

export const getUserCacheQueries = (variables = {}) => {
   return [
      {query: USER_ALL_QUERY, queryPath: 'users'},
      {query: USERS_ALL_WHERE_QUERY, queryPath: 'users', variables},
   ];
};

export const BILLING_ENTRY_ALL_QUERY = gql`
   query billingEntry_All($limit: Int, $offset: Int) {
      billingEntries: billingEntry_All(limit: $limit, offset: $offset) {
         ...billingEntry
      }
   }
   ${BILLING_ENTRY_FRAGMENT}
`;

export const BILLING_ENTRY_ALL_WHERE_QUERY = gql`
   query billingEntry_AllWhere(
      $searchStartDateTime: Timestamp
      $searchEndDateTime: Timestamp
      $personalOnly: Boolean
      $personId: [UUID]
      $sortOrder: [OrderSpec]
      $limit: Int
      $offset: Int
      $createdByUserId: [UUID]
   ) {
      billingEntries: billingEntry_AllWhere(
         billingEntrySearch: {
            searchStartDateTime: $searchStartDateTime
            searchEndDateTime: $searchEndDateTime
            personId: $personId
            createdByUserId: $createdByUserId
         }
         personalOnly: $personalOnly
         sortOrder: $sortOrder
         limit: $limit
         offset: $offset
      ) {
         ...billingEntry
         __typename
      }
      count: billingEntry_Count(
         billingEntrySearch: {
            searchStartDateTime: $searchStartDateTime
            searchEndDateTime: $searchEndDateTime
            personId: $personId
         }
         personalOnly: true
      )
   }
   ${BILLING_ENTRY_FRAGMENT}
`;

export const BILLING_ENTRY_BILLED_TIME = gql`
   query billingEntry_BilledTime(
      $searchStartDateTime: Timestamp
      $searchEndDateTime: Timestamp
      $personalOnly: Boolean
      $personId: [UUID]
   ) {
      billedTime: billingEntry_BilledTime(
         billingEntrySearch: {
            searchStartDateTime: $searchStartDateTime
            searchEndDateTime: $searchEndDateTime
            personId: $personId
         }
         personalOnly: $personalOnly
      )
   }
`;
export const BILLING_ENTRY_CREATE_UPDATE = {
   mutation: gql`
      mutation billingEntryCreateUpdate(
         $id: UUID!
         $name: String
         $note: String
         $personId: UUID
         $activityId: UUID
         $locationId: UUID
         $billingActivityId: UUID
         $startDateTime: Timestamp
         $stopDateTime: Timestamp
      ) {
         billingEntry: billingEntry_CreateUpdate(
            billingEntry: {
               id: $id
               name: $name
               note: $note
               personId: $personId
               activityId: $activityId
               locationId: $locationId
               billingActivityId: $billingActivityId
               startDateTime: $startDateTime
               stopDateTime: $stopDateTime
            }
         ) {
            ...billingEntry
         }
      }
      ${BILLING_ENTRY_FRAGMENT}
   `,
   typeKey: 'billingEntry.type',
   actionKey: CREATE_UPDATE_ACTION,
};

export const BILLING_ENTRY_DELETE = {
   mutation: gql`
      mutation billingEntryDelete($id: UUID!) {
         billingEntry_Delete(billingEntryId: $id)
      }
   `,
   typeKey: 'billingEntry.type',
   actionKey: DELETE_ACTION,
};

export const getBillingEntryCacheQueries = (variables) => {
   return [
      {query: BILLING_ENTRY_ALL_QUERY, queryPath: 'billingEntries'},
      {query: BILLING_ENTRY_ALL_WHERE_QUERY, queryPath: 'billingEntries', variables},
   ];
};

export const PERSONS_ALL_BY_NAME_QUERY = gql`
   query personByName($name: String!) {
      persons: person_AllByNamePartial(name: $name) {
         id
         name
         preferredName
      }
   }
`;

export const PERSONS_ALL_QUERY = gql`
   query persons_all {
      persons: person_All {
         id
         name
         preferredName
      }
   }
`;

export const PERSONS_ALL_WHERE_QUERY = gql`
   query persons_allWhere($tcmId: [UUID]) {
      persons: person_AllWhere(personSearch: {tcmId: $tcmId}) {
         id
         name
         preferredName
      }
   }
`;

export const PERSON_BY_ID_QUERY = gql`
   query personById($personId: UUID!) {
      person: person_ById(personId: $personId) {
         ...personInfo
      }
   }
   ${PERSON_FRAGMENT}
`;

export const PERSON_CREATE_UPDATE = {
   mutation: gql`
      mutation personCreateUpdate(
         $id: UUID!
         $idNumber: String
         $name: String
         $preferredName: String
         $ssn: String
         $usePublicity: Boolean
         $useNewsletter: Boolean
         $allowEmail: Boolean
         $phone: String
         $address: String
         $cityId: UUID
         $stateId: UUID
         $zipCode: String
         $house: String
         $birthDate: DateOnly
         $profileImageS3Data: ImageS3Data
         $age: String
         $gender: String
         $deceasedDate: DateOnly
         $statusId: UUID
         $ethnicityId: UUID
         $entryDate: DateOnly
         $terminationDate: DateOnly
         $terminationReasonId: UUID
         $legalStatus: String
         $useBehaviorPlan: Boolean
         $useRiskAssessment: Boolean
         $medicalCoordinator: String
         $tcmId: UUID
         $alternativeTcmId: UUID
      ) {
         person: person_CreateUpdate(
            person: {
               id: $id
               idNumber: $idNumber
               name: $name
               preferredName: $preferredName
               profileImageS3Data: $profileImageS3Data
               ssn: $ssn
               usePublicity: $usePublicity
               useNewsletter: $useNewsletter
               allowEmail: $allowEmail
               phone: $phone
               address: $address
               cityId: $cityId
               stateId: $stateId
               zipCode: $zipCode
               house: $house
               birthDate: $birthDate
               age: $age
               gender: $gender
               deceasedDate: $deceasedDate
               statusId: $statusId
               ethnicityId: $ethnicityId
               entryDate: $entryDate
               terminationDate: $terminationDate
               terminationReasonId: $terminationReasonId
               legalStatus: $legalStatus
               useBehaviorPlan: $useBehaviorPlan
               useRiskAssessment: $useRiskAssessment
               medicalCoordinator: $medicalCoordinator
               tcmId: $tcmId
               alternativeTcmId: $alternativeTcmId
            }
         ) {
            ...personInfo
         }
      }
      ${PERSON_FRAGMENT}
   `,
   typeKey: 'persons.type',
   actionKey: CREATE_UPDATE_ACTION,
};

export const PERSON_DELETE = {
   mutation: gql`
      mutation personDelete($id: UUID!) {
         person_Delete(personId: $id)
      }
   `,
   typeKey: 'person.type',
   actionKey: DELETE_ACTION,
};

export const getPersonCacheQueries = (variables) => {
   return [
      {query: PERSONS_ALL_QUERY, queryPath: 'persons'},
      {query: PERSONS_ALL_WHERE_QUERY, queryPath: 'persons', variables},
   ];
};

export const STATUS_ALL_QUERY = gql`
   query statusAll {
      status: status_All {
         id
         name
         isDeleted
      }
   }
`;

export const ROLE_ALL_QUERY = gql`
   query rolesAll {
      role: role_All {
         ...roleInfo
      }
   }
   ${ROLE_FRAGMENT}
`;

export const MONTLY_BILLING_REPORT_QUERY = gql`
   query monthlyReport($year: Int, $month: Int, $personalOnly: Boolean, $personIdList: [UUID]) {
      report: monthlyBillingReport(
         year: $year
         month: $month
         personalOnly: $personalOnly
         personIdList: $personIdList
      ) {
         name
         ssn
         medicaidNumber
         tcm
         tcmBillingNumber
      }
   }
`;

export const GET_BILLED_TIME_BY_MONTH = gql`
   query BilledTimeByMonth($year: Int, $month: Int, $personID: UUID) {
      billedTime: billingEntry_BilledTimeByMonth(
         year: $year
         month: $month
         billingEntrySearch: {personId: [$personID]}
         includeDeleted: false
         personalOnly: true
      )
   }
`;
