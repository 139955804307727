import {useCallback} from 'react';
import {useLocation, useNavigate as useNavigateDom} from 'react-router-dom';

export default function useNavigate() {
   const location = useLocation();
   const navigate = useNavigateDom();

   return useCallback(
      (to, options, isPreserveSearch = true) => {
         let useTo;

         if (isPreserveSearch && location.search) {
            useTo = {pathname: to, search: location.search};
         } else {
            useTo = to;
         }
         navigate(useTo, options);
      },
      [location.search, navigate]
   );
}
