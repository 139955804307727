import gql from 'graphql-tag';

// export const CLIENT_FRAGMENT = gql`
//    fragment clientInfo on Client {
//       id
//       name
//       accountId
//       addressLineOne
//       addressLineTwo
//       c3AccountId
//       website
//       cityId
//       city {
//          id
//          name
//       }
//       stateId
//       state {
//          id
//          name
//          abbreviation
//       }
//       zipCode
//       hasFinancials
//       gtcSentDate
//       fein
//       kycCompleted
//       locationIdList
//       flashpointContactId
//       paperworkTypeId
//       paperworkType {
//          id
//          name
//       }
//       w9StatusId
//       w9Status {
//          id
//          name
//       }
//       isDeleted
//       reportFrequency
//       confidence
//       varModel
//    }
// `;

export const CITY_FRAGMENT = gql`
   fragment CityInfo on City {
      id
      name
      label: name
      isDeleted
   }
`;

export const CLIENT_CONTACT_FRAGMENT = gql`
   fragment clientContactInfo on ClientContact {
      id
      name
      label: name
      email
      isPrimary
      contactType {
         id
         name
      }
      contactTypeId
   }
`;

export const CONTACT_SOCIAL_FRAGMENT = gql`
   fragment contactSocialInfo on ContactSocial {
      id
      name
      socialTypeId
      type: socialType {
         id
         name
      }
      clientContactId
      url
   }
`;

export const PHONE_FRAGMENT = gql`
   fragment phoneInfo on ContactPhone {
      id
      phone
      phoneTypeId
      type: phoneType {
         id
         name
      }
      clientContactId
   }
`;

export const STATE_FRAGMENT = gql`
   fragment StateInfo on State {
      id
      name
      label: name
      abbreviation
   }
`;

export const CLIENT_SOCIAL_FRAGMENT = gql`
   fragment clientSocialInfo on ClientSocial {
      id
      clientId
      socialTypeId
      type: socialType {
         id
         name
      }
      url
   }
`;

export const USER_FRAGMENT = gql`
   fragment userInfo on User {
      id
      username
      contactName
      name: contactName
      email
      roleId
      supervisorId
      isDeleted
   }
`;

export const TYPE_FRAGMENT = gql`
   fragment typeInfo on Type {
      id
      name
      category
   }
`;

export const BILLING_ENTRY_FRAGMENT = gql`
   fragment billingEntry on BillingEntry {
      id
      hash
      name
      note
      personId
      activityId
      locationId
      startDateTime
      stopDateTime
      isDeleted
      billingActivityId
      billingActivity {
         id
         name
      }
      createdByUser {
         id
         name: contactName
         username
      }
      updatedByUser {
         id
         name: contactName
         username
      }
      createdDateTime
      updatedDateTime
   }
`;

export const PERSON_FRAGMENT = gql`
   fragment personInfo on Person {
      id
      idNumber
      name
      preferredName
      ssn
      usePublicity
      useNewsletter
      allowEmail
      phone
      address
      cityId
      stateId
      zipCode
      house
      birthDate
      age
      gender
      deceasedDate
      statusId
      ethnicityId
      entryDate
      terminationDate
      terminationReasonId
      legalStatus
      useBehaviorPlan
      useRiskAssessment
      medicalCoordinator
      tcmName
      tcmId
      alternativeTcmId
      profileImageData {
         imageS3
         imageKey
         imageFilename
         imageUpdateDateTime
      }
   }
`;

export const ROLE_FRAGMENT = gql`
   fragment roleInfo on Role {
      id
      name
      createdDateTime
      updatedDateTime
   }
`;
