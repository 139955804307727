import {BUCKET_NAME} from '../Constants';

const awsmobile = {
   aws_project_region: 'us-east-2',
   aws_cognito_identity_pool_id:
      'us-east-2:3b92e7d5-9a41-4668-9b0c-19b86f6fe47d',
   aws_cognito_region: 'us-east-2',
   aws_user_pools_id: 'us-east-2_aAspCEYfH',
   aws_user_pools_web_client_id: '342g0tit9p41sq8q9egncoe59n',
   Storage: {
      AWSS3: {
         bucket: BUCKET_NAME, //REQUIRED -  Amazon S3 bucket
         region: 'us-east-2',
         customPrefix: {
            public: '',
            protected: '',
            private: '',
         },
      },
   },
};
export default awsmobile;
