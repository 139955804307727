import {BUCKET_NAME} from '../Constants';

const awsmobile = {
   aws_project_region: 'us-east-2',
   aws_cognito_identity_pool_id: 'us-east-2:8deab706-e7ef-4a8e-aebe-07696b6f92ce',
   aws_cognito_region: 'us-east-2',
   aws_user_pools_id: 'us-east-2_9ccpyxIR0',
   aws_user_pools_web_client_id: '19uri3ncn0lf5h12d12ijlls5c',

   Storage: {
      AWSS3: {
         bucket: BUCKET_NAME, //REQUIRED -  Amazon S3 bucket
         region: 'us-east-2',
         customPrefix: {
            public: '',
            protected: '',
            private: '',
         },
      },
   },
};
export default awsmobile;
