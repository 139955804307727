import {lazy} from 'react';
import React from 'react';
import {Routes, Route} from 'react-router-dom';
import NoMatch from '../components/NoMatch';
import {
   CASE_NOTES_REPORT_PATH,
   MONTHLY_BILLING_REPORT_PATH,
   PERSONS_PATH,
   PERSON_ITEM_PATH,
   SIGN_OUT_PATH,
   TIME_BILLING_PATH,
   USER_ITEM_PATH,
   USER_PATH,
} from '../Constants';
// import AdminOutlet from '../fhg/components/security/AdminOutlet';
import SuspenseLx from '../fhg/components/SuspenseLx';
const ErrorStateSnackbar = lazy(() => import('../fhg/components/ErrorStateSnackbar'));

const Dashboard = lazy(() => import('./admin/Dashboard'));
const DashboardHome = lazy(() => import('./admin/DashboardHome'));
const TimeBilling = lazy(() => import('./admin/TimeBilling'));
const MonthlyBillingReport = lazy(() => import('./admin/Report/MonthlyBillingReport'));
const CaseNoteReport = lazy(() => import('./admin/Report/CaseNoteReport'));
const PersonEdit = lazy(() => import('./admin/Persons/EditPerson'));
const PersonList = lazy(() => import('./admin/Persons/PersonList'));
const SignOut = lazy(() => import('../fhg/pages/SignOut'));
const ProgressIndicator = lazy(() => import('../fhg/components/ProgressIndicator'));
const ListAndEdit = lazy(() => import('../fhg/pages/ListAndEdit'));
const UserList = lazy(() => import('./admin/user/UserList'));
const UserEdit = lazy(() => import('./admin/user/UserEdit'));

/**
 * Main component accessible only if the user has been authenticated. Contains the routing for the application.
 *
 * Reviewed:
 */
export default function Main() {
   return (
      <>
         <ProgressIndicator isGlobal={true} />
         <ErrorStateSnackbar />
         <Routes>
            <Route path={'/'} element={<SuspenseLx e={<Dashboard />} />}>
               <Route path={'/'} exact element={<SuspenseLx e={<DashboardHome />} />} />
               <Route path={TIME_BILLING_PATH} element={<SuspenseLx e={<TimeBilling />} />} />
               <Route path={MONTHLY_BILLING_REPORT_PATH} element={<SuspenseLx e={<MonthlyBillingReport />} />} />
               <Route path={CASE_NOTES_REPORT_PATH} element={<SuspenseLx e={<CaseNoteReport />} />} />
               {/* <Route path={PERSONS_PATH} element={<Persons />} /> */}
               <Route path={SIGN_OUT_PATH} element={<SignOut />} />
               <Route path={USER_PATH} element={<SuspenseLx e={<ListAndEdit ListComponent={UserList} />} />}>
                  <Route path={`:${USER_ITEM_PATH}`} element={<SuspenseLx e={<UserEdit />} />} />
               </Route>
               <Route path={PERSONS_PATH} element={<SuspenseLx e={<ListAndEdit ListComponent={PersonList} />} />}>
                  <Route path={`:${PERSON_ITEM_PATH}`} element={<SuspenseLx e={<PersonEdit />} />} />
               </Route>
               <Route path='*' element={<NoMatch />} />
            </Route>
         </Routes>
      </>
   );
}
